// @refresh reset
import * as React from "react"

import { Intent, Icon } from "@blueprintjs/core"

import useToaster from "helpers/toaster"

import { useCurrentUser } from "features/auth/withAuth"

import ColorSpinner from "components/spinners/ColorSpinner"

import AuthPlaceholder from "../AuthPlaceholder"
import IManageConfigSelect from "../IManageConfigSelect"
import useIManageAuth from "../useAuth"
import useOnSelectWindowMessage from "../useOnSelectWindowMessage"
import useSaveToDMSLocation from "./useSaveToDMSLocation"

type SaveToProps = {
  onClose?: () => void
  fileUrl: string
}

export default function SaveTo({ onClose = () => null, fileUrl }: SaveToProps) {
  let { currentUser } = useCurrentUser()
  let { success } = useToaster()
  let { config, status, data, reloadOAuthPopup } = useIManageAuth(
    currentUser?.imanage_configs
  )

  let { status: saveStatus, onSelect } = useSaveToDMSLocation({
    ...data,
    fileUrl,
  })

  useOnSelectWindowMessage(onClose, onSelect)

  React.useEffect(() => {
    if (saveStatus === "SUCCESS") {
      success("Document(s) successfully uploaded to iManage")
      onClose()
    }
  }, [saveStatus, onClose])

  return (
    <div className="relative h-full w-full p-3">
      {status === "SELECT_CONFIG" ? (
        <div className="flex h-full w-full">
          <div className="m-auto">
            <IManageConfigSelect {...config} />
          </div>
        </div>
      ) : status === "NOT_AUTHENTICATED" ? (
        <AuthPlaceholder onTryAgain={reloadOAuthPopup} />
      ) : (
        <>
          {data && data.dialogToken && (
            <iframe
              src={`${config.currentConfig.server_host}/work/partner-apps/${data.appId}/dialogs/file-picker/?protocol=postmessage&mode=browse&dialogToken=${data.dialogToken}&types=workspace,folder&customerId=${data.customerId}`}
              className="h-full w-full"
            />
          )}
          {saveStatus !== "INITIAL" && (
            <div className="absolute inset-0 flex rounded bg-gray-800 bg-opacity-60">
              <div className="m-auto rounded bg-white p-5">
                {saveStatus === "SAVING" && (
                  <ColorSpinner className="m-auto" intent="PRIMARY" />
                )}
                {saveStatus === "ERROR" && (
                  <div className="flex w-96 flex-col items-center text-center">
                    <Icon
                      icon="warning-sign"
                      size={54}
                      className="mx-auto"
                      intent={Intent.DANGER}
                    />
                    <h3 className="mb-3 mt-5 font-normal tracking-wider">
                      Something went wrong
                    </h3>
                    <p className="mb-3 px-2">
                      Please refresh the page and try again. If the problem
                      persists, please reach out to{" "}
                      <i>support@simplyagree.com</i>.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
